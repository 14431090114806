<template>
  <v-row>
    <v-col cols="auto">
      <campaigns-carousel></campaigns-carousel>
    </v-col>
    <v-col cols="auto">
      <rewards-carousel></rewards-carousel>
    </v-col>
    <v-col cols="auto">
      <news-carousel></news-carousel>
    </v-col>
  </v-row>
</template>
<script>
export default {
  components: {
    NewsCarousel: () => import("@/components/widgets/NewsCarousel"),
    RewardsCarousel: () => import("@/components/widgets/RewardsCarousel"),
    CampaignsCarousel: () => import("@/components/widgets/CampaignsCarousel")
  },
  data() {
    return {};
  }
};
</script>
